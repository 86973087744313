import React from "react"
import Layout from "../components/Layout"
import ContactForm from "../components/ContactForm"

export default ({ location }) => (
  <Layout location={location}>
    <div className="section-inner">
      <div className="alignhalf">
        <ContactForm />
      </div>
    </div>
  </Layout>
)
